import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";

import { AuthenticationService } from "./shared/services/api/authentication.service";
import { NotificationService } from "./shared/services/notification.service";
import { BrandNotSelectedError } from "./shared/errors/brand-not-selected-error";
import { PartnerNotSelectedError } from "./shared/errors/partner-not-selected-error";
import { SentryService } from "./shared/services/sentry.service";

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly injector: Injector,
    private readonly router: Router,
    private readonly sentryService: SentryService,
    private readonly translateService: TranslateService,
  ) {}

  public handleError(receivedError: Error): void {
    if (
      !receivedError ||
      (typeof receivedError !== "object" && typeof receivedError !== "string")
    ) {
      try {
        // TEMP: Increase limit to try to get some relevant information
        Error.stackTraceLimit = 500;
        const err = new Error(
          "Invalid Error Object. Original Type: " +
            typeof receivedError +
            ". Message: " +
            JSON.stringify(receivedError),
        );

        console.info("Error Stack: ", err.stack);
        throw err;
      } catch (error) {
        this.captureError(error);
      }
      return;
    }

    const error = this.extractError(receivedError);

    // To avoid errors, use injector to get the `NotificationService`
    // See: https://github.com/scttcper/ngx-toastr/issues/179
    const notificationService = this.injector.get(NotificationService);

    if (error instanceof HttpErrorResponse) {
      this.handleHttpError(error, notificationService);
    } else if (
      error instanceof BrandNotSelectedError ||
      error instanceof PartnerNotSelectedError
    ) {
      this.captureError(error);
      notificationService.error(error.message);
      this.router.navigateByUrl("/welcome");
    } else if (error instanceof Error) {
      this.captureError(error);
      this.handleGenericError(error, notificationService);
    } else if (typeof error === "string") {
      this.captureError(error);
      notificationService.error(error);
    }
  }

  private captureError(error: unknown) {
    console.error(error);
    this.sentryService.captureException(error);
  }

  private extractError(error: Error): Error | string {
    // Extract `rejection` error from promises `UncaughtPromiseError`
    if (this.isUncaughtPromiseError(error)) {
      return error.rejection;
    }

    return error;
  }

  private handleHttpError(
    error: HttpErrorResponse,
    notificationService: NotificationService,
  ): void {
    if (error.status === 401) {
      this.authenticationService.forceLogout();
      notificationService.warning("shared.sessionExpired");
    } else if (!navigator.onLine) {
      notificationService.error("shared.noInternetConnection");
    } else {
      notificationService.error(`${error.status} - ${error.message}`);
    }
  }

  private handleGenericError(
    error: Error,
    notificationService: NotificationService,
  ): void {
    const { BUNDLE_ERRORS, STORAGE_ERRORS } = this.sentryService;
    const msg = error.message;
    const isBundleLoadingError = BUNDLE_ERRORS.some((err) => err.test(msg));
    const isCookiesOrStorageError = STORAGE_ERRORS.some((err) => err.test(msg));

    if (isBundleLoadingError) {
      this.handleBundleLoadingError();
    } else if (isCookiesOrStorageError) {
      this.handleCookiesOrStorageError();
    } else if (msg) {
      notificationService.error(msg, error.name);
    } else {
      notificationService.error("errorDialog.unexpectedError");
    }
  }

  private handleBundleLoadingError(): void {
    alert(this.translateService.instant("shared.errorLoadingChunk"));
    window.location.reload();
  }

  private handleCookiesOrStorageError(): void {
    const noCookiesPagePath = "/no-cookies";

    if (location.pathname !== noCookiesPagePath) {
      this.router.navigateByUrl(noCookiesPagePath);
    }
  }

  private isUncaughtPromiseError(error: Error): error is UncaughtPromiseError {
    return "promise" in error && "rejection" in error;
  }
}
